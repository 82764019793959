import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Input from '../input/input';
import Button from '../button/button';
import {
  NewsletterWrapper,
  NewsletterInnerWrapper,
  NewsletterTitle,
  NewsletterDescription,
  NewsletterInputWrapper,
  ErrorMessage,
  SuccessMessage,
} from './newsletter.style';

import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

type NewsletterProps = {};

const Newsletter: React.FunctionComponent<NewsletterProps> = ({ ...props }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(({ msg, result }: any) => {
        if (result !== 'success') {
          throw msg;
        }
        setSuccess(msg);
        setError('');
        setEmail('');
      })
      .catch((err: any) => {
        setError(err);
        setSuccess('');
        setEmail('');
      });
  };
  return (
    <NewsletterWrapper {...props}>
      <Helmet>
        <script src={withPrefix('script2.js')} type="text/javascript" />
        <script src={withPrefix('script3.js')} type="text/javascript" />
      </Helmet>
      <NewsletterInnerWrapper>
        <NewsletterTitle>
          Don't miss an article!
        </NewsletterTitle>
        
        {/*<NewsletterInputWrapper action="https://thibaultvandermostenblog.hosted.phplist.com/lists/?p=subscribe" method="post">
          {success ? (
            <SuccessMessage>{success} 🙂</SuccessMessage>
          ) : (
            <>
              <Input
                type="email"
                name="email"
                placeholder="Write your email here"
                onChange={handleChange}
                value={email}
                required={true}
              />
              <Button title="Subscribe" type="submit" />
            </>
          )}
          </NewsletterInputWrapper>*/}
        <div id="phplistsubscriberesult"></div>
        <NewsletterInputWrapper action="https://thibaultvandermostenblog.hosted.phplist.com/lists/?p=subscribe&id=1" method="post" id="phplistsubscribeform">
          {success ? (
            <SuccessMessage>{success} 🙂</SuccessMessage>
          ) : (
            <>
              <Input
                type="email"
                name="email"
                placeholder="Write your email here"
                onChange={handleChange}
                value={email}
                required={true}
                id="emailaddress"
              />
              <Button title="Subscribe" type="submit" id="phplistsubscribe"/>
            </>
          )}
        </NewsletterInputWrapper>

        {/*<NewsletterInputWrapper onSubmit={handleSubmit}>
          {success ? (
            <SuccessMessage>{success} 🙂</SuccessMessage>
          ) : (
            <>
              <Input
                type="email"
                name="email"
                placeholder="Write your email here"
                onChange={handleChange}
                value={email}
                required={true}
              />
              <Button title="Subscribe" type="submit" />
            </>
          )}
          </NewsletterInputWrapper>*/}
        {error && (
          <ErrorMessage
            dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }}
          />
        )}
      </NewsletterInnerWrapper>
    </NewsletterWrapper>
  );
};

export default Newsletter;
